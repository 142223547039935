export const initNavigationDropdown = () => {
  const mainMenu = document.getElementById("menu-main-menu");

  if (!mainMenu) {
    return;
  }

  const menuItems = mainMenu.querySelectorAll(
    ".main-menu-item.menu-item-has-children"
  );

  // Create a Map to store the isOpened state for each item
  const isOpenedMap = new Map();
  const isOpenedSubMap = new Map();

  const closeAllTabs = () => {
    menuItems.forEach((item) => {
      item.classList.remove("is-active");
      // Set isOpened to false for this item
      isOpenedMap.set(item, false);
    });
  };

  menuItems.forEach((item) => {
    const iconButton = item.querySelector("button");
    const subMenuItems = item.querySelectorAll(
      ".menu-item-depth-1.menu-item-has-children"
    );

    // Initialize isOpened to false for this item
    isOpenedMap.set(item, false);

    iconButton.addEventListener("click", (event) => {
      const isOpened = isOpenedMap.get(item);
      if (!isOpened) {
        closeAllTabs();
        item.classList.add("is-active");
        isOpenedMap.set(item, true);
      } else {
        item.classList.remove("is-active");
        isOpenedMap.set(item, false);
      }
    });

    subMenuItems.forEach((subItem) => {
      const subIconButton = subItem.querySelector("button");

      // Initialize isOpened to false for this item
      isOpenedSubMap.set(subItem, false);

      subIconButton.addEventListener("click", (event) => {
        const isOpened = isOpenedSubMap.get(subItem);
        if (!isOpened) {
          subItem.classList.add("is-active");
          isOpenedSubMap.set(subItem, true);
        } else {
          subItem.classList.remove("is-active");
          isOpenedSubMap.set(subItem, false);
        }
      });
    });
  });
};
