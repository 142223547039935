import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Internal dependencies
import { animateHeader } from "./animations/header";
import { animateHero } from "./animations/hero";
import { animateServiceBoxes } from "./animations/service-boxes";
import { animatePageElements } from "./animations/page-elements";
import { animateNavigation } from "./animations/navigation";
import { initSlider } from "./animations/slider";
import { initImageBgAnimation } from "./animations/image";

gsap.registerPlugin(ScrollTrigger);

// animate function
export const animate = () => {
  animateHeader(ScrollTrigger);
  animateHero(gsap);
  animateServiceBoxes();
  animatePageElements(gsap, ScrollTrigger);
  animateNavigation(gsap);
  initSlider(gsap, Power4);
  initImageBgAnimation(gsap);
};
