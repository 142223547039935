export const initSlider = (gsap, Power4) => {
  const slider = document.querySelector(".ads-testimonials-slider");
  const prev = document.querySelector(".btn.prev");
  const next = document.querySelector(".btn.next");

  if (!slider || !next || !prev) return;

  gsap.set(".ads-testimonials-slider", { autoAlpha: 1 });
  gsap.set(".ads-testimonials-slide", { x: "-100%" });

  let currentStep = 1;
  const totalSlides = document.querySelectorAll(
    ".ads-testimonials-slide"
  ).length;

  let trIn;

  createTimelineIn("next", currentStep);

  function createTimelineIn(direction, index) {
    const goPrev = direction === "prev";
    const element = document.querySelector("div.project--" + index),
      projectClasses = element.className.split(" "),
      projectClass = projectClasses[1];

    const tlIn = gsap.timeline({
      defaults: {
        modifiers: {
          x: gsap.utils.unitize(function (x) {
            return goPrev ? Math.abs(x) : x;
          }),
        },
      },
    });
    tlIn.fromTo(
      element,
      {
        autoAlpha: 0,
        x: "-100%",
      },
      {
        duration: 0.6,
        x: 0,
        autoAlpha: 1,
        ease: Power4.out,
        onStartParams: [projectClass],
      }
    );

    return tlIn;
  }

  function createTimelineOut(direction, index) {
    const goPrev = direction === "prev";
    const element = document.querySelector("div.project--" + index),
      tlOut = gsap.timeline();

    tlOut.to(element, {
      duration: 0.7,
      x: 250,
      autoAlpha: 0,
      modifiers: {
        x: gsap.utils.unitize(function (x) {
          return goPrev ? -x : x;
        }),
      },
      ease: "back.in(2)",
    });

    return tlOut;
  }

  function updateCurrentStep(gotoIndex) {
    currentStep = gotoIndex;
  }

  function transition(direction, toIndex) {
    const tlTransition = gsap.timeline({
        onStart: function () {
          updateCurrentStep(toIndex);
        },
      }),
      trOut = createTimelineOut(direction, currentStep);
    (trIn = createTimelineIn(direction, toIndex)),
      tlTransition.add(trOut).add(trIn);

    return tlTransition;
  }

  function isTweening() {
    return gsap.isTweening(".ads-testimonials-slide");
  }

  if (totalSlides <= 1) {
    prev.style.display = "none";
    next.style.display = "none";
    return;
  }

  prev.addEventListener("click", (evt) => {
    evt.preventDefault();

    if (totalSlides.length <= 1) {
      console.log(totalSlides.length);
      return;
    }

    const isFirst = currentStep === 1;
    const prevStep = isFirst ? totalSlides : currentStep - 1;
    !isTweening() && transition("prev", prevStep);
  });

  next.addEventListener("click", (evt) => {
    evt.preventDefault();

    if (totalSlides.length <= 1) {
      return;
    }

    const isLast = currentStep === totalSlides;
    const nextStep = isLast ? 1 : currentStep + 1;
    !isTweening() && transition("next", nextStep);
  });
};
