export const animateNavigation = (gsap) => {
  const body = document.querySelector("body");
  const adsHeader = document.querySelector(".ads-header");
  const adsHeaderGroup = document.querySelector(".ads-header-group");
  const backdrop = document.querySelector(".ads-backdrop");
  const im = document.querySelector(".ads-header-logo img");

  // OPEN TRIGGER
  const openTrigger = document.querySelector(".ads-header-menu-trigger");
  const openTriggerTop = openTrigger.querySelector(
    ".ads-header-menu-trigger-bar.top"
  );
  const openTriggerMiddle = openTrigger.querySelector(
    ".ads-header-menu-trigger-bar.middle"
  );
  const openTriggerBottom = openTrigger.querySelector(
    ".ads-header-menu-trigger-bar.bottom"
  );

  // CLOSE TRIGGER
  const closeTrigger = document.querySelector(".ads-header-close-trigger");
  const closeTriggerLeft = closeTrigger.querySelector(
    ".ads-header-close-trigger-bar.left"
  );
  const closeTriggerRight = closeTrigger.querySelector(
    ".ads-header-close-trigger-bar.right"
  );

  // MENU
  const menuContainer = document.querySelector(".ads-header-menu-container");
  const menuTop = document.querySelector(".ads-header-menu-bg.top");
  const menuMiddle = document.querySelector(".ads-header-menu-bg.middle");
  const menuBottom = document.querySelector(".ads-header-menu-bg.bottom");

  //TL
  var tlOpen = gsap.timeline({ paused: true });
  var tlClose = gsap.timeline({ paused: true });

  //OPEN TIMELINE
  tlOpen
    .add("preOpen")
    .to(
      adsHeader,
      { duration: 0, height: "96px", ease: "power4.inOut" },
      "open"
    )
    .to(
      adsHeaderGroup,
      { duration: 0, height: "96px", ease: "power4.inOut" },
      "open"
    )
    .to(
      openTriggerTop,
      {
        duration: 0.4,
        x: "+=80",
        y: "-=80",
        delay: 0.1,
        ease: "power4.in",
        onComplete: function () {
          closeTrigger.style.zIndex = "25";
        },
      },
      "preOpen"
    )
    .to(
      openTriggerMiddle,
      {
        duration: 0.4,
        x: "+=80",
        y: "-=80",
        ease: "power4.in",
        onComplete: function () {
          openTrigger.style.visibility = "hidden";
        },
      },
      "preOpen"
    )
    .to(
      openTriggerBottom,
      { duration: 0.4, x: "+=80", y: "-=80", delay: 0.2, ease: "power4.in" },
      "preOpen"
    )
    .add("open", "-=0.4")
    .fromTo(
      menuContainer,
      { x: "100%", opacity: 1, visibility: "hidden" },
      {
        duration: 0.6,
        x: 0,
        opacity: 1,
        visibility: "visible",
        ease: "power4.out",
      },
      "-=0.2"
    )
    .add("preClose", "-=0.8")
    .to(
      closeTriggerLeft,
      { duration: 0.8, x: "-=100", y: "+=100", ease: "power4.out" },
      "preClose"
    )
    .to(
      closeTriggerRight,
      {
        duration: 0.8,
        x: "+=100",
        y: "+=100",
        delay: 0.2,
        ease: "power4.out",
      },
      "preClose"
    );

  //CLOSE TIMELINE
  tlClose
    .add("close")
    .to(
      menuTop,
      {
        duration: 0.2,
        backgroundColor: "#6295ca",
        ease: "power4.inOut",
        onComplete: function () {
          closeTrigger.style.zIndex = "5";
          openTrigger.style.visibility = "visible";
        },
      },
      "close"
    )
    .to(
      menuMiddle,
      { duration: 0.2, backgroundColor: "#6295ca", ease: "power4.inOut" },
      "close"
    )
    .to(
      menuBottom,
      { duration: 0.2, backgroundColor: "#6295ca", ease: "power4.inOut" },
      "close"
    )
    .to(
      menuContainer,
      {
        duration: 0.6,
        x: "100%",
        // opacity: 0,
        ease: "power4.out",
        onComplete: function () {
          menuContainer.style.visibility = "hidden";
        },
      },
      "close"
    )
    .to(
      closeTriggerLeft,
      { duration: 0.2, x: "+=100", y: "-=100", ease: "power4.in" },
      "close"
    )
    .to(
      closeTriggerRight,
      { duration: 0.2, x: "-=100", y: "-=100", delay: 0.1, ease: "power4.in" },
      "close"
    )
    .to(
      openTriggerTop,
      { duration: 1, x: "-=80", y: "+=80", delay: 0.2, ease: "power4.out" },
      "close"
    )
    .to(
      openTriggerMiddle,
      { duration: 1, x: "-=80", y: "+=80", ease: "power4.out" },
      "close"
    )
    .to(
      openTriggerBottom,
      { duration: 1, x: "-=80", y: "+=80", delay: 0.1, ease: "power4.out" },
      "close"
    )
    .to(
      adsHeaderGroup,
      { duration: 0, delay: 0, height: "96px", ease: "power4.inOut" },
      "close",
      "+=0.2"
    )
    .to(
      adsHeader,
      { duration: 0, delay: 0, height: "96px", ease: "power4.inOut" },
      "close",
      "+=0.2"
    );

  //EVENTS
  openTrigger.addEventListener("click", function () {
    body.classList.add("menu-open");
    backdrop.classList.add("is-active");

    const width = window.innerWidth < 420;

    if (width) {
      setTimeout(function () {
        im.style.filter = "brightness(1) invert(1)";
      }, 600);
    }

    if (tlOpen.progress() < 1) {
      tlOpen.play();
    } else {
      tlOpen.restart();
    }
  });

  closeTrigger.addEventListener("click", function () {
    body.classList.remove("menu-open");
    backdrop.classList.remove("is-active");
    const width = window.innerWidth < 420;

    if (width) {
      setTimeout(function () {
        im.style.filter = "brightness(1) invert(0)";
      }, 100);
    }

    if (tlClose.progress() < 1) {
      tlClose.play();
    } else {
      tlClose.restart();
    }
  });
};
