export const initImageBgAnimation = (gsap) => {
  const imageBg = document.querySelector(".ads-animated-image");

  if (!imageBg) return;

  imageBg.addEventListener("mousemove", (e) => {
    imageBg.classList.add("ads-in");
  });

  imageBg.addEventListener("mouseleave", (e) => {
    imageBg.classList.remove("ads-in");
  });
};
