export const animatePageElements = (gsap, ScrollTrigger) => {
  const animateFrom = (elem, direction) => {
    direction = direction || 1;
    let x = 0,
      y = direction * 240;
    if (elem.classList.contains("ads-reveal-from-left")) {
      x = -240;
      y = 0;
    } else if (elem.classList.contains("ads-reveal-from-right")) {
      x = 240;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(
      elem,
      { x: x, y: y, autoAlpha: 0 },
      {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
      }
    );
  };

  const hide = (elem) => {
    gsap.set(elem, { autoAlpha: 0 });
  };

  gsap.utils.toArray(".ads-reveal").forEach(function (elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function () {
        animateFrom(elem);
      },
      onEnterBack: function () {
        animateFrom(elem, -1);
      },
      onLeave: function () {
        hide(elem);
      },
    });
  });
};
