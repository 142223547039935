export const animateServiceBoxes = () => {
  const serviceBoxRows = document.querySelectorAll(
    ".ads-services-row .ads-service-boxes"
  );

  if (!serviceBoxRows.length) return;
  if (window.innerWidth < 905) return;

  window.addEventListener("resize", () => {
    if (window.innerWidth < 905) {
      // If window width is less than 905, remove the active class from all boxes
      serviceBoxRows.forEach((box) => {
        box.removeAttribute("data-selected-box");
        const serviceBoxes = box.querySelectorAll(".ads-service-box");
        serviceBoxes.forEach((serviceBox) => {
          serviceBox.classList.remove("ads-service-box--active");
        });
      });
    }
  });

  serviceBoxRows.forEach((box) => {
    const serviceBoxes = box.querySelectorAll(".ads-service-box");
    serviceBoxes.forEach((serviceBox, index) => {
      serviceBox.addEventListener("mouseenter", () => {
        box.dataset.selectedBox = index;
        serviceBox.classList.add("ads-service-box--active");
      });
      serviceBox.addEventListener("mouseleave", () => {
        box.removeAttribute("data-selected-box");
        serviceBox.classList.remove("ads-service-box--active");
      });
    });
  });
};
