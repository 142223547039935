// animateHero function
export const animateHero = (gsap) => {
  const heroHeadline = document.querySelector(".ads-button-in-tl");

  if (!heroHeadline) return;

  let tl = gsap.timeline({
    delay: 0.3,
    defaults: {
      duration: 0.15,
      ease: "none",
    },
    smoothChildTiming: true,
    autoRemoveChildren: true,
  });

  tl.to(".ads-button-in-tl", {
    opacity: 1,
    y: 0,
  });
};
