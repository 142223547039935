export const animateHeader = (ScrollTrigger) => {
  const getHeader = document.querySelector(".ads-header");
  const getDesktopHeader = document.querySelector(".ads-header-desktop");

  if (getHeader) {
    const animateHeader = ScrollTrigger.create({
      start: "top var(--wp--custom--spacing--p-y)",
      end: 999999,
      toggleClass: {
        className: "ads-header--scrolled",
        targets: ".ads-header",
      },
    });
  }

  if (getDesktopHeader) {
    const animateHeader = ScrollTrigger.create({
      start: "top var(--wp--custom--spacing--p-y)",
      end: 999999,
      toggleClass: {
        className: "ads-header-desktop--scrolled",
        targets: ".ads-header-desktop",
      },
    });
  }
};
